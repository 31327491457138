%absCenter {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
}

.percent {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
    svg {
        &.sm {
            width: 5.5rem;
            height: 5.5rem;
        }
        &.md {
            width: 7rem;
            height: 7rem;
        }
        &.lg {
            width: 9rem;
            height: 9rem;
        }
    }
    &__value {
        @extend %absCenter;
        font-weight: 600;
        font-size: 1.6rem;
        display: grid;
        width: 100%;
        align-items: center;
        justify-content: center;
        color: var(--color-active-light);
        &[data-over] {
            color: var(--color-red);
        }
    }
}
