.checkCell {
    display: grid;
    grid-auto-flow: row;
    gap: 0.5rem;
    justify-items: center;
    align-items: center;
    &__label {
        font-size: 1.8rem;
        cursor: pointer;
    }
    &__cell {
        border: 2px solid var(--color-active-trs);
        border-radius: 0.3rem;
        width: 3rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .icon {
            fill: var(--color-active-light);
            visibility: hidden;
            opacity: 0;
            transition: all 0.2s ease-in-out;
        }
        &:hover:not(.checkCell__cell--checked){
            .icon{
                visibility: visible;
                opacity: .2;
            }
        }
        &--checked {
            @extend .checkCell__cell;
            .icon {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
