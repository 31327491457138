.sidebar {
    @media print {
        display: none !important;
        width: 0 !important;
        height: 0 !important;
    }
    $self: &;
    grid-column: sidebar / center-start;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 15rem 1fr;
    font: inherit;
    background-color: var(--color-white);
    min-height: 100vh;
    padding-top: 2rem;
    &__select {
        padding: 1rem;
        // display: grid;
        // grid-gap: 2rem;
    }
    // &__nav{
    //     display: flex;
    //     flex-direction: column;
    //     align-items: flex-start;
    //     justify-content: start;
    // }

    &__sub {
        background-color: var(--color-bg-3);
        display: grid;
        width: 100%;
        #{$self}__text {
            display: list-item;
            list-style-type: square;
        }
        @include respond(tab-port) {
            display: none;
        }
    }

    &__link {
        align-self: center;
        width: 100%;
        padding: 2rem;
        // padding-right: 2rem;
        font: inherit;
        display: grid;
        grid-template-columns: 3rem 1fr;
        grid-gap: 0.2rem 2rem;
        color: var(--color-non-active);
        // border-radius: 5rem;
        transition: all 0.3s ease-out;
        &:hover .tooltip {
            visibility: visible;
            opacity: 0.8;
            width: fit-content;
            // max-width: 90%;
            transition-delay: 1.3s;
        }
        @include respond(tab-port) {
            grid-gap: 0.2rem;
        }

        @include highlightRow($end, hover);
        &:hover {
            background-color: var(--color-bg-2);
            cursor: pointer;
        }
        &--active {
            color: var(--color-active);
            &::before {
                visibility: visible;
                height: 80% !important;
                opacity: 1 !important;
            }
        }
        &.sub {
            padding: 1rem;
            padding-right: 8rem;
            align-self: stretch;
            border-radius: 0;
            display: flex;
            align-items: center;
            @include respond(tab-port) {
                display: none;
            }
        }
    }
    &__text {
        font: inherit;
        font-size: 1.8rem;
        @include respond(tab-port) {
            display: none;
        }
    }
    &__hr {
        border-bottom: 0.5px solid var(--color-non-active);
        border-radius: 5px;
        width: 50%;
        opacity: 0.4;
        align-self: center;
        margin: 2rem auto;
    }

    // MVP:
    &__sub {
        display: none;
    }

    &__link {
        grid-gap: 0.2rem;
        &.sub {
            display: none;
        }
    }
    &__text {
        display: none;
    }
}
