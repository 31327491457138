.prompt {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    @media print {
        display: block !important;
        padding: 0;
        margin: 0 auto;
        background-color: white !important;
        top: 0 !important;
        position: relative !important;
        * {
            font-size: 12pt !important;
            // color: black !important;
            // background-color: white !important;
        }
        .button {
            display: none !important;
        }
    }
    &__box {
        @media print {
            width: -webkit-fill-available !important;
            // height: -webkit-fill-available !important;
            padding: 0 !important;
            margin: 0 auto !important;
            border-radius: none !important;
            box-shadow: none !important;
        }
        padding: 3rem;
        padding-top: 4rem;
        position: relative;
        cursor: context-menu;
        min-width: 30rem;
        min-height: 30rem;
        background-color: white;
        border-radius: 1rem;
        box-shadow: var(--box-shadow-3);
        display: grid;
        justify-items: center;
        align-items: center;
        gap: 2rem;
        max-height: 100vh;
        overflow-y: scroll;
        @include scrollbar();
        .topActions {
            z-index: 1;
            position: absolute;
            right: 1rem;
            top: 0;
            height: 4rem;
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            gap: 1rem;
            .icon {
                fill: white;
            }
            .close {
                &:hover {
                    background-color: var(--color-red-trs);
                    transform: scale(1.5);
                }
            }
        }
        &.hide {
            background-color: transparent !important;
            & > *:not(.prompt__title):not(.topActions) {
                display: none !important;
            }
        }
    }
    .full-width {
        min-width: 70vw;
        min-height: 90vh;
        max-width: 90vw;
        align-content: flex-start;
        justify-items: stretch;
        overflow-y: scroll !important;
    }
    &__title {
        @extend .no-print;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: cornflowerblue;
        border-radius: 1rem 1rem 0 0;
        color: #f8f8f8;
        padding: 1rem;
        text-align: center;
        font-size: 1.8rem;
        font-weight: 500;
        max-height: 4rem;
        padding: 0.5rem;
    }
    &__msg {
        font-size: 1.8rem;
        padding: 1rem;
        white-space: pre-wrap;
    }
    &__note {
        width: 100% !important;
        border: 1px solid lightgray;
        padding: 0.8rem;
        outline: none;
        font-size: 1.8rem;
        overflow: auto;
    }
    &__actions {
        @extend .no-print;
        display: grid;
        gap: 2rem;
        justify-content: center;
        align-items: center;
        grid-auto-flow: column;
    }
    &__action {
        min-width: 7rem;
    }
    &__otherActions {
        @extend .no-print;
        display: grid;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        border-top: 3px solid lightgray;
        padding: 2rem;
        margin-top: 2rem;
        background-color: var(--color-gray-3);

        .button {
            background-color: var(--color-active-light);
        }
    }
}

.formEdit {
    position: relative;
    padding: 1rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    &__caption {
        grid-column: 1 / -1;
    }
    &__actions {
        grid-column: 1 / -1;
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        margin-bottom: 2rem;
        gap: 2rem;
        width: 100%;
        position: sticky;
        bottom: 0;
        .button {
            min-width: 15rem;
            justify-self: center;
        }
    }
    &__flag {
        position: absolute;
        left: 1rem;
        top: 1rem;
    }
}
