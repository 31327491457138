.actionList{
    transition: all .2s ;
    display: grid;
    width: 100%;
    min-width: fit-content;
    border-radius: 1rem;
    background-color: var(--color-white);
    color: var(--color-non-active);
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    box-shadow: var(--box-shadow-1);
    &__row{
        font: inherit;
        cursor: pointer;
        font-size: 2rem;
        display: grid;
        align-items: center;
        justify-items: flex-end;
        padding: 1rem 2rem;
        grid-auto-flow: column;
        gap: 1rem;
        @include highlightRow($end,hover);
        &:hover{
            background-color: var(--color-bg-2);
        }
    }
}
