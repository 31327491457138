.section {
    grid-column: 1/-1;
    position: relative;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    min-height: auto;
    .caption {
        padding: 1rem;
        grid-column: 1/-1;
        transition: all 0.2s;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
            background-color: var(--color-gray-trs);
        }
        .icon-collaspe {
            height: 3rem;
            width: 3rem;
        }
    }
    //prompt form
    .selectedApartment {
        & > .iconButton {
            padding: 1rem;
            .icon {
                width: 4.5rem;
                height: 4.5rem;
            }
        }
    }
    &__toggleTableView {
    }
    &__flag {
        position: absolute;
        left: 1rem;
        top: 1rem;
    }
    &__actions {
        margin-top: 2rem;
        grid-column: 1/-1;
        display: grid;
        grid-auto-flow: column;
    }
    &__action {
        background-color: var(--color-gray-trs);
        font-size: 1.6rem;
        font-weight: 500;
        display: grid;
        align-items: center;
        justify-content: center;
        justify-items: center;
        padding: 1rem;
        color: var(--color-active);
        cursor: pointer;
        transition: all 0.2s ease-in;
        &:not(:last-child) {
            border-left: 1px solid var(--color-bg-1);
        }
        .icon {
            fill: var(--color-active);
        }
        &:hover {
            background-color: var(--color-bg-1);
        }
    }
    .nav,
    .table {
        grid-column: 1/-1;
    }
    &__milestones {
        display: grid;
        grid-column: 1/-1;
        margin-top: 2rem;
        position: relative;
    }
    &__milestone {
        .input,
        .input__field {
            // width: 100% !important;
            min-width: fit-content !important;
        }
        border: 1px solid var(--color-bg-2);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        font-size: 1.8rem;
        overflow: hidden;
        &--total {
            background-color: var(--color-active);
            color: #fff;
            font-weight: 500;
        }
        &--columnTotal {
            background-color: cornflowerblue;
            position: relative;
            overflow: visible;
        }
        &--title {
            background-color: #c4d79b;
            position: relative;
            .flag {
                position: absolute;
                right: 0;
                top: 0;
                margin: 1px;
                border-radius: 0.5rem;
                z-index: 50;
            }
            &:hover > .action {
                visibility: visible;
                opacity: 1;
            }
            .action {
                z-index: 10;
                visibility: hidden;
                opacity: 0;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 50%;
                transform: translateY(-50%);
                left: 5px;
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
                background-color: var(--color-gray-trs);
                cursor: pointer;
                transition: all 0.2s ease-in;
                &:hover {
                    background-color: var(--color-white);
                    & > .icon {
                        transform: scale(1.1);
                    }
                }
            }
        }
        &--value {
            background-color: #ffff99;
            span {
                cursor: text;
                font: inherit;
            }
        }
        &--unit {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &--actual {
            position: relative;
            text-align: center;
            .action {
                width: 5rem;
                height: 5rem;
                visibility: hidden;
                opacity: 0;
                position: absolute;
                top: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: translateY(-50%);
                border-radius: 50%;
                padding: 0.5rem;
                transition: all 0.2s ease-in;
                cursor: pointer;
                color: var(--color-gray-2);
                font-weight: 500;
                &:hover {
                    background-color: var(--color-bg-3);
                    .icon {
                        fill: var(--color-active);
                        transform: scale(1.1);
                    }
                }
                &--minus {
                    right: 0;
                    &:active {
                        .icon {
                            fill: var(--color-red-trs);
                        }
                    }
                }
                &--plus {
                    left: 0;
                    &:active {
                        .icon {
                            fill: var(--color-active-light);
                        }
                    }
                }
            }
            .prompt-btn {
                @extend .action;
                right: -2rem;
                width: 4rem;
                height: 4rem;
            }
            &:hover {
                & .action {
                    opacity: 1;
                    visibility: visible;
                }
            }
            &::after {
                content: attr(data-label);
                position: absolute;
                left: 0;
                font-size: 1.8rem;
                padding: 1.5rem;
                font-weight: 600;
                color: var(--color-active-light);
            }
        }
        &.span-r {
            &--2 {
                grid-row: span 2;
            }
        }
        .input__field {
            padding: 0;
            background-color: inherit;
            text-overflow: ellipsis;
            text-align: center;
            border: none;
            width: 90%;
            height: 100%;
        }
        .errorFlag {
            position: absolute;
            left: 0;
            top: 0;
            white-space: pre-wrap;
            overflow: visible;
        }
    }
    &__save {
        grid-column: 1/-1;
        justify-self: center;
        margin-top: 3rem;
    }
}
