.groups {
    display: grid;
    justify-content: flex-start;
    align-items: center;
    justify-items: center;
    gap: 1rem;
    grid-auto-flow: column;
    .group {
        display: grid;
        gap: 0.5rem;
        min-width: 15rem;
        max-height: 15rem;
        cursor: pointer;
        justify-items: center;
        align-items: center;
        align-content: flex-start;
        padding: 1rem;
        border-radius: 2rem;
        position: relative;
        transition: all 0.2s ease-in-out;
        grid-template-columns: 1fr min-content;
        grid-template-rows: repeat(auto-fit, minmax(5rem, 1fr));
        &--open {
            box-shadow: inset -2px -2px 6px 0px #2349a929;
        }
        &__folder {
            width: 4rem;
            height: 4rem;
            fill: var(--color-active-light);
            grid-column: 1;
            grid-row: 1;
            transition: all 0.2s ease-in-out;
        }
        &__actions {
            display: grid;
            grid-column: 2;
            grid-row: 1 / -1;
            visibility: hidden;
            opacity: 0;
            width: 0;
            transition: all 0.2s ease-in-out;
        }
        &:hover:not(.group--close) .group__actions {
            width: 100%;
            visibility: visible;
            opacity: 1;
        }
        &__action {
            background-color: var(--color-gray-trs);
            font-size: 1.4rem;
            font-weight: 500;
            display: grid;
            align-items: center;
            justify-content: center;
            justify-items: center;
            padding: 1rem;
            color: var(--color-non-active);
            cursor: pointer;
            transition: all 0.2s ease-in;
            &:not(:last-child) {
                border-bottom: 1px solid var(--color-bg-1);
            }
            .icon {
                fill: var(--color-non-active);
            }
            &:hover {
                background-color: var(--color-bg-1);
            }
        }
        &__title {
            color: var(--color-active-light);
            font-size: 1.8rem;
            font-weight: 600;
            grid-row: 2;
            grid-column: 1;
        }
    }
}