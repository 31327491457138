.nav {
    align-self: flex-start;
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    @extend .no-print;
    &--page {
        grid-column: 1 / -1;
        justify-self: flex-start;
        min-width: 50rem;
        & .nav__link {
            font-size: 2.4rem;
            font-weight: 500;
        }
    }
    &__link {
        text-align: center;
        border-bottom: 1px solid lightgray;
        font: inherit;
        font-size: 2rem;
        font-weight: 500;
        color: var(--color-non-active);
        transition: all 0.2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        &:not(.nav__link--active) {
            @include highlightRow("bottom", hover);
            &[data-path]:hover {
                text-decoration: underline;
            }
        }
        &:hover {
            color: var(--color-active);
        }
        &--active {
            color: var(--color-active);
            border-bottom: 2px solid var(--color-active);
        }
    }
}
//TODO: merge styles

.navRect {
    &__link {
        text-align: center;
        padding: 1rem 2rem;
        background: var(--color-white);
        font: inherit;
        font-size: 2.2rem;
        color: var(--color-non-active);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &[data-path]:hover {
            text-decoration: underline;
        }
        &--active {
            background-color: var(--color-active);
            color: var(--color-white);
        }
    }
}

.navArrow {
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    align-items: center;

    &__link:nth-child(odd) {
        font-weight: var(--font-w-3);
        font-size: 15px;
    }
    &__link:nth-child(even) {
        font-size: 15px;
    }
    &__link:last-child,
    &__link:last-child::after,
    &__link:last-child::before {
        background: var(--color-active);
        color: var(--color-white);
    }
    &__link {
        text-align: center;
        padding: 1rem 2rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        background: var(--color-white);
        font: inherit;
        font-size: 2.2rem;
        color: var(--color-non-active);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &[data-path]:hover {
            text-decoration: underline;
        }
        &::before {
            content: "";
            position: absolute;
            height: 100%;
            right: 0;
            transform: translateX(2rem);
            width: 4rem;
            background: white;
            clip-path: polygon(0% 0%, 100% 0, 50% 50%, 100% 100%, 0% 100%);
        }
        &::after {
            content: "";
            position: absolute;
            height: 100%;
            left: 0;
            transform: translateX(-2rem);
            width: 2rem;
            background: var(--color-white);
            clip-path: polygon(100% 0, 0 50%, 100% 100%);
        }
    }
}
