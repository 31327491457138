.chart {
    display: grid;
    grid-template-areas:
        "title title title title"
        "chart chart chart captions"
        "chart chart chart captions"
        "chart chart chart captions";
    .caption {
        grid-area: title;
    }
    &__captions {
        grid-area: captions;
        display: grid;
        align-items: center;
        align-content: center;
        justify-content: center;
        gap: 2rem;
        .caption {
            cursor: pointer;
            display: grid;
            grid-template-columns: min-content 1fr 1fr;
            gap: 1.5rem;
            align-items: center;
            transition: all 0.2s ease-in;
            padding: 1rem;
            &:hover {
                background-color: var(--color-gray-trs);
            }
            &__color {
                width: 5rem;
                height: 5rem;
                border-radius: 50%;
            }
            &__text {
                font-size: 1.8rem;
                color: var(--color-active);
                font-weight: 600;
            }
            &__value {
                display: grid;
                align-items: center;
                justify-content: center;
                span {
                    font: inherit;
                    text-align: center;
                }
                .done {
                    border-bottom: 0.5px solid #add8e6d1;
                    font-size: 1.6rem;
                }
                .total {
                    font-size: 2.2rem;
                    font-weight: 600;
                }
            }
        }
    }
    .columnChart {
        %gridlinevalue {
            position: absolute;
            left: 0;
            color: var(--color-active-light);
            font-size: 1.4rem;
            font-weight: 500;
        }
        %hover {
            transform: scale(1.05);
            transform-origin: bottom;
            backface-visibility: hidden;
            box-shadow: var(--box-shadow-3);
        }
        position: relative;
        grid-area: chart;
        content: "";
        display: grid;
        gap: 1rem;
        justify-content: center;
        grid-template-columns: repeat(auto-fit, minmax(5rem, max-content));
        align-items: flex-end;
        background-size: 3rem 3rem;
        background-image: repeating-linear-gradient(#ccccccbf 0 0.1px, transparent 1px 100%);
        height: 30rem;
        border-bottom: 1px solid var(--color-active-trs);
        &__col {
            content: "";
            position: relative;
            transition: all 0.2s ease-in;
            cursor: pointer;
            &:hover {
                @extend %hover;
            }
            &[data-hover] {
                @extend %hover;
                opacity: 0.7;
            }
            .tooltip {
                z-index: 1;
            }
        }
        &__min {
            @extend %gridlinevalue;
            bottom: 0;
        }
        &__max {
            @extend %gridlinevalue;
            top: 0;
        }
    }
}
