.printPage {
    @page {
        font-size:A4;
    }
    table {
        page-break-inside: auto;
    }
    tr {
        display: block;
        page-break-inside: avoid;
        page-break-after: auto;
    }
    td,
    th { 
        width: 100%;
        font-size: 2rem;
        border: 1px solid;
    }
    thead {
        display: table-header-group;
    }
    tfoot {
        display: table-footer-group;
    }
}
