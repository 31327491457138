.vendor {
    &__details {
        position: relative;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    }
}
.contact {
    &__details {
        position: relative;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    }
}