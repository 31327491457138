.paymentFormBox {
    min-width: 70vw;
    min-height: 90vh;
    align-content: flex-start;
    justify-items: stretch;
    overflow-y: scroll !important;
}

.paymentForm {
    display: grid;
    gap: 1rem;

    &__details {
        @extend .formEdit;
    }
    &__create {
        display: grid;
        grid-column: 1/-1;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: min-content 1fr;
        gap: 1rem;
        background-color: var(--color-bg-layout);
        border: 2px solid #ebe8e8;
        padding: 2rem;
        .input__field {
            grid-row: 2;
            background-color: var(--color-white);
        }
        .cell__list {
            overflow-y: scroll;
        }
        .button {
            grid-row: 2;
            justify-self: end;
        }
        .checkCell {
            grid-row: 2;
            align-self: center;
            justify-self: center;
        }
    }
    &__summery {
        grid-column: 1/-1;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .caption {
        grid-column: 1/-1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
        cursor: pointer;
        &:hover {
            .icon {
                fill: var(--color-active);
                transform: scale(1.1);
            }
        }
        &--collaspe {
            @extend .caption;
            box-shadow: var(--box-shadow-light);
        }
    }
    .actions {
        grid-column: 2;
        display: grid;
        gap: 1rem;
        grid-auto-flow: column;
        justify-content: flex-end;
        align-items: center;
        .action {
            color: #fff;
            background-color: var(--color-active-light);
            padding: 0.5rem 2rem;
            border-radius: 0.5rem;
            cursor: pointer;
        }
    }
    &__table {
        display: grid;
        .caption {
            margin-top: 2rem;
        }
        .cell {
            border: 1px solid #ecf0f6;
            padding: 0.5rem;
            font-size: 1.6rem;
            &--header {
                background-color: navy;
                color: #fff;
                text-align: center;
                font-weight: 500;
            }
            &--value {
                cursor: text;
                &:hover {
                    border: 1px dotted #ecf0f6;
                }
                &.checked {
                    background-color: var(--color-gray-trs);
                }
            }
            &--select {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.2s ease-in-out;
                &:hover {
                    .checkCell {
                        transform: scale(1.1);
                    }
                }
            }
            .input__field {
                border: none;
            }
        }
    }
    .save {
        grid-column: 1/-1;
        justify-self: center;
        margin-top: 2rem;
    }
}
