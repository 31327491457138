.card {
    background-color: var(--color-white);
    border-radius: 1.8rem;
    min-height: 20rem;
    width: 100%;
    padding: 3rem;
    display: grid;
    gap: 1rem;
    font-size: 2.5rem;
    align-content: flex-start;
    position: relative;
    &__caption {
        font: inherit;
        font-size: 2.4rem;
        font-family: var(--font-family-2);
        font-weight: 700;
    }
    &__empty {
        font: inherit;
        font-size: 3rem;
        text-align: center;
    }
    &--add {
        align-items: center;
        align-content: center;
        justify-content: center;
        transition: all 0.2s ease-in;
        cursor: pointer;
        grid-column: 1/-1;
        @include activateButton();
        .add {
            font-size: 3rem;
            color: var(--color-active-light);
            font-weight: 500;
        }
    }
    &--scroll {
        @include scrollbar();
    }

    &__actions {
        display: grid;
        gap: 2rem;
        justify-content: center;
        align-items: center;
        grid-auto-flow: column;
    }
}
