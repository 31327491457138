.snackbar {
    font-size: 3rem;
    font-family: inherit;
    min-width: 250px;
    margin-left: -125px;
    // background-color: var(--color-active-light);
    background-color: var(--color-active-dark); //THINK:
    color: var(--color-white);
    text-align: center;
    border-radius: 15px;
    padding: 16px;
    position: fixed;
    z-index: 1000;
    left: 50%;
    bottom: 3rem;
    border: 1px solid white;
    box-shadow: var(--box-shadow-2);
    // animation: fadein 0.5s, fadeout 0.5s 2.5s;
    display: grid;
    grid-auto-flow: column;
    &--error {
        background-color: var(--color-red-trs);
    }

    &__text {
    }
    .fadein {
        animation: fadein 0.5s;
    }
    .fadeout {
        animation: fadeout 0.5s 2.5s;
    }
    @keyframes scale {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(2);
        }
    }
    @keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }
        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    @keyframes fadeout {
        from {
            bottom: 30px;
            opacity: 1;
        }
        to {
            bottom: 0;
            opacity: 0;
        }
    }
}
