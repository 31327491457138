.monthSelect {
    width: 100%;
    min-width: 5rem;
    font-size: 1.8rem;
    position: relative;
    &__input {
        padding: 0;
        margin: 0;
        outline: none;
        width: 100%;
        height: 100%;
        text-align: center;
        direction: ltr;
        border: 1px solid gray;
        cursor: pointer;
        :focus-within {
            border: 1px dotted lightgray;
        }
    }
    &__selected{
        outline: none;
    }
    &__list{
        background-color: white;
        padding: .5rem;
        background-color: white;
        box-shadow: var(--box-shadow-1);
        border-radius: 0 0 .5rem .5rem;
    }
    &__item{
        font-size: 1.4rem;
        padding: .5rem;
        border-bottom: 1px solid #d3d3d369;
        transition: all .2s ease-in-out;
        @include highlightRow($end,hover);
        cursor: pointer;
        &--disabled{
            color: gray;
            cursor: default;
        }
    }
}
