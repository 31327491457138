.login{
    background-color: var(--color-active-light);
    min-height: 100vh;
    background: linear-gradient(45deg, var(--color-active-light), var(--color-active-dark),var(--color-active-light));
    padding: 3vw;
    display: grid;
    justify-content: center;
    align-content: center;
    .input{
        position: relative;
        overflow: inherit;
    }
    .show{
        position: absolute;
        left: 1rem;
        bottom: 25%;
        cursor: pointer;
    }
    .card{
        display: grid;
        gap: 10rem;
        justify-items: center;
        padding: 3rem;
    }
    .button{
        min-width: 80%;
        justify-self: center;
    }
    &__form{
        display: grid;
        min-width: 320px;
        grid-gap: 5rem;
        align-content: center;
    }
    &__hint{
        background-color: var(--color-bg-2);
        border-radius: 1rem;
        color: var(--color-non-active);
        font: inherit;
        font-size: 2rem;
        text-align:center;
        height: 7rem;
        display: grid;
        align-content: center;
        align-self: stretch;
        justify-self: stretch;
    }
}