.layout--reports {
    @extend .layout;
    @media print {
        max-width: 210mm;
        & .no-print {
            @media print {
                display: none !important;
            }
        }
        & .print-only {
            display: none !important;
            @media print {
                display: block !important;
            }
        }
        // padding: 0;
        // * {
        //     font-size: 12pt !important;
        //     color: black;
        //     background-color: white;
        // }
        // .footer {
        //     display: none;
        // }
        // .formCard {
        //     display: none;
        // }
        // .table {
        //     &__row {
        //         border: none;
        //     }
        //     &__cell {
        //         border: 1px solid gray;
        //         width: 100%;
        //         height: 100%;
        //     }
        // }
    }
}
.reports {
    display: grid;
    gap: 1rem;
    &__title {
        display: grid;
        grid-auto-flow: column;
        margin-bottom: 2rem;
        align-items: center;
        @media print {
            margin: 0;
            padding: 0;
            .caption {
                font-size: 14pt;
            }
        }
        .logo {
            grid-column: 1;
            justify-self: flex-start;
        }
        .caption {
            grid-column: 2;
        }
        .icon {
            grid-column: 3;
            justify-self: flex-end;
            width: 3.5rem;
            height: 3.5rem;
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            &:hover {
                transform: scale(1.1);
            }
            @media print {
                display: none;
            }
        }
    }
    &__report {
        display: grid;
        gap: 1rem;
        @media print {
            padding: 1rem;
        }
        .card {
            min-height: 0;
        }
        .table {
            &__cell {
                // text-decoration: none;
            }
        }
        .reportBody {
            display: grid;
            gap: 1rem;
        }
    }
}
.reportBuilder {
    display: grid;
    gap: 1rem;
    &__breadCrumbs {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
        gap: 2rem;
        justify-content: flex-start;
        .breadCrumb {
            padding: 1rem;
            border-radius: 0.5rem;
            color: var(--color-active-light);
            font-size: 2.4rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;
            &:not(.disabled) {
                cursor: pointer;
                @include highlightRow("bottom", hover);
            }
            .icon {
                fill: var(--color-white);
            }
            &.done {
                background-color: var(--color-active-trs);
            }
            &.disabled {
                color: var(--color-non-active);
                .icon {
                    fill: var(--color-non-active);
                }
            }
            &.active {
                color: var(--color-white);
                background-color: var(--color-active);
            }
        }
    }
    &__selectModel {
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        align-items: center;
        .button {
            grid-column: 1 / -1;
            margin-top: 2rem;
        }
        .model {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            border-radius: 0.5rem;
            border: 1px solid lightgray;
            transition: all 0.2s ease-in-out;
            @include activateButton();
            &--selected {
                @extend .model;
                background-color: var(--color-active-trs);
                font-weight: 500;
            }
        }
    }
    &__selectHeaders {
        @extend .reportBuilder__selectModel;
        .button {
            grid-column: 1 / -1;
            margin-top: 2rem;
        }
        .header {
            @extend .model;
            &--selected {
                @extend .model--selected;
            }
        }
    }
}

.filterForm {
    display: grid;
    gap: 2rem;
}
