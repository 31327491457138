.user {
    &__details {
        position: relative;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        .caption{
            grid-column: 1/-1;
            margin-top: 3rem;
            margin-bottom: 2rem;
        }
    }
    &__img {
        cursor: pointer;
        width: 7rem;
        height: 7rem;
        object-fit: cover;
        border-radius: 50%;
        position: absolute;
        left: 0;
    }
    &__options {
        margin-top: 5rem;
    }
}
