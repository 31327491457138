.project {
    &__details {
        position: relative;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    }
    &__accountsReport {
        display: grid;
        gap: 2rem;
        background: white;
        position: relative;
        .actions {
            @extend .no-print;
            display: grid;
            grid-auto-flow: column;
            gap: 1rem;
            align-items: center;
            justify-content: flex-end;
        }
        .cell {
            font-size: 2rem;
            text-align: right;
            display: grid;
            align-content: center;
            user-select: text;
        }
    }
}
