//HIGHLIGHT:
.show{
    animation: show .6s ease-in-out;
}
@keyframes show{
    0%{
        opacity: .5;
    }
    100%{
        opacity: 1;
    }
}

//shake:
@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

//FADE IN:
.fade-in{
    animation: fade-in .5s ease-in;
}
@keyframes fade-in{
    0%{
        transform: translateX(-60%);
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
//FADE IN:
.fade-in-left{
    animation: fade-in-left .5s ease-in;
}
@keyframes fade-in-left{
    0%{
        transform: translateX(70%);
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}

//FADE OUT:
.fade-out{
    animation: fade-out .4s ease-out;
}
@keyframes fade-out{
    0%{
        transform: translateX(0%);
        opacity: 1;
    }
    100%{
        transform: translateX(100%);
        opacity: 0;
    }
}




@keyframes moveInLeft {
    0% {
        opacity: 0;
        transform: translateX(-10rem);
    }

    80% {
        transform: translateX(1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInRight {
    0% {
        opacity: 0;
        transform: translateX(10rem);
    }

    80% {
        transform: translateX(-1rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}

@keyframes moveInBottom {
    0% {
        opacity: 0;
        transform: translateY(3rem);
    }

    100% {
        opacity: 1;
        transform: translate(0);
    }
}