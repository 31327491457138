.userBox{
    background-color: var(--color-bg-3);
    display: grid;
    padding: 1rem;
    position: relative;
    &__name{
        font: inherit;
        font-size: 1.5rem;
        background-color: var(--color-bg-layout);
        border-radius: 5px;
        text-align: center;
    }
    &__img{
        cursor: pointer;
        width: 7rem;
        height: 7rem;
        object-fit: cover;
        border-radius: 50%;
        display: block;
        justify-self: center;
    }
    &--open{
        position: absolute;
        display: grid;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        background-color: white;
        box-shadow: var(--box-shadow-light);
        border-radius: 1rem;
        width: max-content;
    }
}


