%droparea {
    border: 5px dotted lightgray;
    color: var(--color-non-active);
}
.tableFiles {
    .droparea {
        @extend %droparea;
    }
    .table {
        min-height: 15rem;
        align-content: flex-start;
    }
}
.filePrompt {
    display: grid;
    gap: 1rem;
    &__form {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    }
    &__preview {
        justify-self: center;
        display: grid;
        justify-content: center;
        .showFile {
            object-fit: contain;
            max-height: 60vh;
        }
    }
    span {
        justify-self: center;
    }
}
.filesUpload {
    $self: &;
    grid-column: 1/-1;
    display: grid;
    .droparea {
        @extend %droparea;
    }
    &__thumbs {
        display: grid;
        justify-content: start;
        min-width: 50rem;
        gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    }
    label#{$self}__thumb {
        transition: all 0.2s ease-in-out;
        @include activateButton();
        cursor: pointer;
    }
    &__thumb {
        width: 20rem;
        background-color: var(--color-gray-trs);
        border-radius: 1rem;
        padding: 1rem;
        display: grid;
        align-items: center;
        justify-items: center;
        position: relative;
        color: var(--color-active-light);
        font-size: 2rem;
        img {
            object-fit: cover;
            width: 100%;
        }
        .remove {
            @extend %icon-close-box;
            fill: var(--color-red-trs);
            &:hover {
                fill: white;
            }
        }
        .attach {
            fill: var(--color-active-trs);
        }
    }
}
