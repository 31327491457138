.layout {
	min-width: fit-content;
	background-color: var(--color-bg-layout);
	@include scrollbar();
	.page {
		display: grid;
		grid-template-columns: [sidebar] 30rem [center-start] 1fr [center-end];
		position: relative;
		@media print {
			padding: 0 !important;
			display: block !important;
		}
		main.content {
			padding: 3rem;
			display: grid;
			gap: 1rem;
			align-content: flex-start;
			// & > *:not(.print) {
			// 	@media print {
			// 		display: none !important;
			// 		width: 0 !important;
			// 		height: 0 !important;
			// 	}
			// }
		}
		@include respond(tab-port) {
			grid-template-columns: [sidebar] 7rem [center-start] 1fr [center-end];
		}
		//:
		grid-template-columns: [sidebar] 7rem [center-start] 1fr [center-end];
	}
	.footer {
		@media print {
			display: none !important;
			width: 0 !important;
			height: 0 !important;
		}
		background: var(--color-active-light);
		width: 100%;
		height: 5rem;
		color: white;
		display: grid;
		grid-auto-flow: column;
		align-items: center;
		align-content: center;
		grid-template-columns: 1fr min-content;
		padding: 3rem;
		.copy {
			font: inherit;
			font-size: 2rem;
			text-align: center;
		}
		.bug {
			border-radius: 50%;
			width: 5rem;
			height: 5rem;
			background-color: #c7380d36;
			border: 1px solid var(--color-pending);
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.2s ease-in-out;
			@include activateButton();
			.icon {
				fill: var(--color-bg-layout);
			}
		}
	}
}
.layout--fullPage {
	@extend .layout;
	min-height: 100vh;
	.fullPage {
		&__title {
			display: grid;
			grid-auto-flow: column;
			align-items: center;
			grid-row-gap: 2rem;
			grid-column-gap: 1rem;
			grid-template-columns: min-content 1fr;
			grid-template-rows: min-content max-content;
			.breadCrumbs {
				grid-row: 1;
				grid-column: 1 / -1;
			}
			.arrow {
				grid-row: 2;
				grid-column: 1;
				justify-self: flex-start;
				cursor: pointer;
				transition: all 0.2s ease-in;
				.icon {
					width: 4rem;
					height: 4rem;
				}
			}
			.heading {
				grid-row: 2;
				text-align: center;
			}
		}
	}
}
