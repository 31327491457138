.indexCalc {
    // &__chapters {
    // }
    &__chapter {
        @extend .table__row;
        &--selected {
            @extend .table__row;
            @extend .table__row--selected;
        }
    }
    &__info {
        display: grid;
        gap: 2rem;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        .info,
        .caption,
        .button {
            grid-column: 1/-1;
        }
        .button {
            justify-self: center;
            margin-top: 2rem;
            align-self: center;
        }
    }
}
