.icon {
    //initial size
    width: 2rem;
    height: 2rem;
    fill: var(--color-non-active);
    display: flex;
    justify-content: center;
    align-items: center;
    backface-visibility: hidden;
    &--active {
        fill: var(--color-active);
    }
    &--md {
        width: 2.4rem;
        height: 2.4rem;
    }
    &--lg {
        width: 3rem;
        height: 3rem;
    }
    &--xl {
        width: 5rem !important;
        height: 5rem !important;
    }

    &--highlight {
        cursor: pointer;
        transition: all 0.2s ease-in;
        &:hover {
            transform: scale(1.1);
            background-color: var(--color-bg-3);
        }
    }
}
.iconButton {
    cursor: pointer;
    transition: all 0.2s ease-in;
    justify-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 7rem;
    max-height: 7rem;
    &:hover {
        transform: scale(1.2);
    }
    &:active {
        transform: scale(1);
    }
}
