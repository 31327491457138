.breadCrumbs {
    display: grid;
    grid-auto-flow: column;
    gap: 1rem;
    align-items: center;
    overflow-x: scroll;
    grid-column: 1/-1;
    justify-content: flex-start;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s ease-in-out;
        border-radius: 1rem;
    }
    @include respond(phone) {
        &:hover::-webkit-scrollbar {
            visibility: visible;
            opacity: 1;
        }
    }
    &__link {
        color: white;
        background-color: var(--color-active-light);
        font: inherit;
        font-size: 1.8rem;
        border-radius: 1rem;
        font-weight: 550;
        padding: 0.2rem 1rem;
        transition: all 0.2s;
        white-space: nowrap;
        display: grid;
        grid-auto-flow: column;
        gap: 0.5rem;
        align-items: center;
        &[data-path]:hover {
            cursor: pointer;
            // background-color: var(--color-bg-3); //OLD_VERSION
            text-decoration: underline; //NEW
            transform: scale(1.07); //NEW
        }
    }
    &__iconList {
        visibility: hidden;
        opacity: 0;
        width: 0;
        transition: all 0.2s ease-in;
        fill: white;
    }
    &__link:hover > &__iconList {
        visibility: visible;
        opacity: 1;
        width: 3rem;
    }
}

//OLD VERSION VIEW
// .breadCrumbs{
//     display: grid;
//     grid-auto-flow: column;
//     gap: 1rem;
//     align-items: center;

//     &__link:nth-child(odd){

//         font-weight: var(--font-w-3);
//         font-size: 15px;
//     }
//     &__link:nth-child(even){

//       font-size: 15px;
//     }
//     &__link:last-child , &__link:last-child::after , &__link:last-child::before{

//         background: var(--color-active);
//         color:var(--color-white)
//     }
//     &__link{
//         text-align: center;
//         padding: 1rem 2rem;
//         margin-left: 1.5rem;
//         margin-right: 1.5rem;
//         background: var(--color-white);
//         font: inherit;
//         font-size: 2.2rem;
//         color: var(--color-non-active);
//         position: relative;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         cursor: pointer;
//         &[data-path]:hover{
//             text-decoration: underline;
//         }
//         &::before{
//             content: "";
//             position: absolute;
//             height: 100%;
//             right: 0;
//             transform: translateX(2rem);
//             width: 4rem;
//             background: white;
//             clip-path: polygon(0% 0%, 100% 0, 50% 50%, 100% 100%, 0% 100%);
//         }
//         &::after{
//             content: "";
//             position: absolute;
//             height: 100%;
//             left: 0;
//             transform: translateX(-2rem);
//             width: 2rem;
//             background: var(--color-white);
//             clip-path: polygon(100% 0, 0 50%, 100% 100%);
//         }
//     }
// }
