.loader{
    position: fixed; 
    display: none; 
    width: 100%;
    height: 100%; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0, .5); 
    z-index: 100; 
    cursor: pointer;
    
    &--active{
        display: grid;
        justify-content: center;
        align-content: center;
    }
    &__spinner{
        border: 16px solid var(--color-non-active); 
        border-top: 16px solid var(--color-active); 
        border-radius: 50%;
        width: 12rem;
        height: 12rem;
        animation: spin 2s linear infinite;
          
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
    }
}

@import '../../../sass/animations'
