.contractPlan {
    grid-template-columns: 1fr;
    &__details {
        grid-column: 1/-1;
        position: relative;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    }
    .contract__comments{
        grid-column: 1/-1;
    }
    .sections {
        display: grid;
        gap: 1rem;
    }
    .section {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        .loader-dots {
            grid-column: 1/-1;
        }
    }
    .prompt--form {
        min-width: 70%;
        min-height: 50%;
    }
}

