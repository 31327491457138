.cell {
    white-space: nowrap;
    display: grid;
    position: relative;
    &--display {
        background-color: var(--color-gray-trs);
    }
    &__pending {
        width: 1.2rem;
        height: 1.2rem;
        position: absolute;
        bottom: 1rem;
        left: 0;
        background-color: var(--color-red-trs);
        border-radius: 50%;
        transition: transform 0.2s ease-in-out;
        z-index: 100;
        cursor: pointer;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            transform: scale(1.5);
        }
        &:active {
            transform: scale(0.9);
        }
        .actions {
            position: absolute;
            bottom: 100%;
            display: grid;
            gap: 0.2rem;
            .icon {
                fill: var(--color-active-light);
            }
        }
    }
    &__field {
        &--select {
            position: relative;
            cursor: pointer;
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;
            .icon--remove {
                fill: var(--color-red-trs);
                opacity: 0;
                visibility: hidden;
                transition: all 0.2s;
                cursor: pointer;
                &:hover {
                    transform: scale(1.5);
                    opacity: 1;
                }
            }
            &:hover .icon--remove {
                opacity: 0.7;
                visibility: visible;
            }
            .multiple {
                display: grid;
                gap: 1rem;
                grid-auto-flow: column;
                align-items: center;
                &__item {
                    display: flex;
                    border-radius: 1rem;
                    padding: 0.5rem;
                    font-size: 1.4rem;
                    background-color: var(--color-bg-2);
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }
        }
    }
    &__search {
        display: grid;
        grid-template-columns: 1fr min-content;
        align-items: center;
        padding: 0.3rem;
        background-color: var(--color-gray-3);
        input {
            border: none;
            border-radius: 1rem;
            outline: none;
            padding: 0.5rem;
            font-size: 1.6rem;
            color: var(--color-active-light);
            width: 100%;
        }
    }
    &__list {
        margin-top: 0.2rem;
        background-color: white;
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        display: grid;
        z-index: 100;
        box-shadow: var(--box-shadow-light);
        // overflow: hidden;
    }
    &__list-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }
    &__list-item {
        cursor: pointer;
        padding: 1rem;
        white-space: pre-wrap;
        &:not(:last-child) {
            border-bottom: 1px solid var(--color-bg-3);
        }
        @include highlightRow($end, hover);
        &:hover {
            background-color: var(--color-bg-2);
        }
        &--add {
            color: var(--color-active);
            font-size: 1.6rem;
            border-top: 2px solid var(--color-active-trs);
        }
        &--selected {
            color: var(--color-non-active);
            background-color: var(--color-gray-trs);
        }
    }

    .checkCell {
        @extend .input__field;
        justify-content: flex-start;
        &__label {
            font: inherit;
        }
    }

    .menuButton {
        position: absolute;
        left: 0;
        top: 10%;
    }
    // &__error {
    //     position: absolute;
    //     bottom: 100%;
    //     background-color: white;
    //     z-index: 100;
    //     color: var(--color-red-trs);
    //     padding: 0.5rem;
    //     font-weight: var(--font-w-3);
    //     font-size: 1.6rem;
    //     border-radius: 1rem;
    //     box-shadow: var(--box-shadow-light);
    // }
    // &__icon-error {
    //     position: absolute;
    //     top: 0.5rem;
    //     left: 0.5rem;
    //     fill: var(--color-red-trs);
    //     cursor: pointer;
    // }
    .popUp__form {
        width: 100%;
        padding: 2rem 0 0 2rem;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        gap: 2rem;
        .button {
            margin-top: 2rem;
        }
    }
}

.input {
    display: flex;
    flex-direction: column-reverse;
    cursor: pointer;
    overflow: hidden !important;
    position: relative;
    width: 100%;
    &__sign {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.8rem;
        color: var(--color-gray-1);
    }
    .menuButton {
        position: absolute;
        left: 0;
        top: 10%;
        &--right {
            @extend .menuButton;
            right: 0;
        }
    }
    &.password {
        .show {
            position: absolute;
            left: 1rem;
            bottom: 25%;
            cursor: pointer;
        }
    }
    &--select {
        overflow: visible !important;
    }
    &__field {
        font: inherit;
        // width: 100%;
        font-size: 1.8rem;
        padding: 1.5rem;
        font-weight: var(--font-w-2);
        border: none;
        border-bottom: 1px solid lightgray;
        outline: none;
        text-overflow: ellipsis;
        transition: all 0.2s ease-in-out;

        &--text {
            overflow: hidden;
            text-overflow: ellipsis;
            user-select: text;
            // flex: 1;
        }

        &--error {
            border-bottom: 1px solid var(--color-red-trs);
            color: var(--color-red-trs);
        }

        &:read-only {
            cursor: context-menu;
            color: var(--color-gray);
        }

        &[data-id] {
            cursor: pointer;
            text-decoration: underline;
            color: var(--color-active);
            transition: all 0.3s;
        }

        &:disabled {
            cursor: context-menu;
            background: none;
            border-bottom: 0.5px solid var(--color-gray-3);
        }

        &:focus:not(:read-only) {
            background-color: var(--color-bg-2);
        }

        &:invalid {
            border-bottom: 2px solid var(--color-red-trs);
            color: var(--color-red-trs);
        }
    }
    &__label {
        color: var(--color-gray-1);
        font-size: 1.9rem;
        padding-right: 1.5rem;
        font-weight: var(--font-w-2);
        transition: all 0.2s;
        &--error {
            color: var(--color-red-trs);
        }
        // &::before {
        //     visibility: hidden;
        //     opacity: 0;
        //     content: attr(data-error);
        //     border-radius: 2rem;
        //     position: absolute;
        //     top: 100%;
        //     z-index: 100;
        //     background-color: white;
        //     color: var(--color-red-trs);
        //     font: inherit;
        //     font-weight: var(--font-w-3);
        //     font-size: 1.6rem;
        // }
        // &[data-error]::before {
        //     visibility: visible;
        //     opacity: 1;
        // }
    }

    &:focus-within &__label {
        color: cornflowerblue;
        font-weight: var(--font-w-3);
    }
    &:focus-within &__label--error {
        color: var(--color-red-trs);
        font-weight: var(--font-w-3);
    }

    &:focus-within &__field {
        border-bottom: 0.5px solid cornflowerblue;
    }
    &:focus-within &__field--error {
        border-bottom: 0.5px solid var(--color-red-trs);
    }

    &:hover .tooltip {
        visibility: visible;
        opacity: 0.8;
        width: fit-content;
        // max-width: 90%;
        transition-delay: 1.3s;
    }
}

.InputCards {
    $self: &;
    grid-column: 1/-1;
    display: grid;
    &__thumbs {
        display: grid;
        justify-content: start;
        min-width: 50rem;
        gap: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    }
    &__thumb {
        min-width: 20rem;
        min-height: 20rem;
        background-color: var(--color-gray-trs);
        border-radius: 1rem;
        padding: 1rem;
        display: grid;
        align-items: center;
        justify-items: center;
        position: relative;
        color: var(--color-active-light);
        font-size: 2rem;
        .remove {
            @extend %icon-close-box;
        }
    }
    &__input {
        color: var(--color-active);
        font-weight: 500;
        text-overflow: ellipsis;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
    &__thumb--add {
        @extend .InputCards__thumb;
        transition: all 0.2s ease-in-out;
        @include activateButton();
        cursor: pointer;
    }
    &__totals {
        display: grid;
        align-items: center;
        justify-content: center;
        border-top: 5px solid var(--color-active-trs);
        background-color: var(--color-bg-3);
        .total {
            color: var(--color-active-light);
            padding: 0.2rem;
            &__label {
                font-size: 2.4rem;
                font-weight: 500;
            }
            &__value {
                font-size: 2.8rem;
            }
        }
    }
}
