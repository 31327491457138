.iconsBlock {
    .icons {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(auto-fit,minmax(20rem,auto));
        &__icon {
            display: grid;
            align-items: center;
            justify-items: center;
            border: 1px solid lightgrey;
            padding: 1rem;
            border-radius: 1rem;
            .id {
                user-select: all;
            }
            .icon {
                width: 7rem;
                height: 7rem;
            }
        }
    }
}
