.tableCard {
    position: relative;
    display: grid;
    padding: 1.5rem 1.5rem 2.5rem;
    grid-column: 1/-1;
    &__totalsFunc {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
        gap: 1rem;
    }
    // &__add {
    //     justify-self: flex-end;
    //     align-self: center;
    // }
    // &__add{
    //     position: absolute;
    //     bottom: .5rem;
    //     right: .5rem;
    //     z-index: 1;
    //     border-radius: 50%;
    //     width: 4rem;
    //     height: 4rem;
    // }
    &__add {
        position: absolute;
        bottom: -1rem;
        // right: -1rem;
        left: -1rem;
        z-index: 1;
        border-radius: 50%;
        width: 4.5rem;
        height: 4.5rem;
        box-shadow: var(--box-shadow-light);
    }
    &__caption {
        grid-column: 1/-1;
    }
}

.table {
    display: grid;
    &__headers {
        display: grid;
        align-items: start;
        justify-content: start;
        border-bottom: 3px solid var(--color-gray-3);
        .table__cell {
            font-size: 2.2rem;
            font-weight: 500;
        }
        &.sticky {
            position: sticky;
            top: 0;
            background: #fffffff0;
            z-index: 9999;
            box-shadow: var(--box-shadow-light);
            @media print {
                position: relative;
            }
        }
    }
    &__totals {
        display: grid;
        align-items: start;
        justify-content: start;
        border-top: 5px solid var(--color-active-trs);
        background-color: var(--color-bg-3);
        .total {
            color: var(--color-active-light);
            font-size: 2.4rem;
            padding: 0.2rem;
        }
    }
    &__row {
        position: relative;
        display: grid;
        align-items: start;
        justify-content: start;
        cursor: pointer;
        color: var(--color-gray-1);
        &--selected {
            background-color: var(--color-active-trs);
            color: var(--color-active);
            font-weight: 400;
        }
        &--done {
            color: var(--color-non-active);
        }
        &--current {
            color: var(--color-pending);
        }
        &--due {
            background-color: var(--color-pending);
            color: #fff;
        }
        &:not(.table__row--selected):not(.table__headers):not(.table__totals):hover {
            background-color: var(--color-gray-trs);
            transition: background 0.5s;
        }
        &:not(.table__headers) {
            border-bottom: 1px solid #f8f8f8;
        }
        &--new {
            color: var(--color-pending);
        }
        &:hover > .table__cell--actions {
            visibility: visible;
            opacity: 1;
        }
    }

    &__row--group {
        @extend .table__row;
        cursor: pointer;
        color: var(--color-non-active);
        background-color: var(--color-bg-3);
        border: none;
        justify-content: center;
        font-size: 2rem;
        font-weight: 500;
        cursor: pointer;
    }
    &__row--subGroup {
        @extend .table__row;
        border-right: 2rem solid var(--color-bg-2);
        background-color: var(--color-gray-trs);
    }

    &__cell {
        padding: 0.5rem;
        margin: 0 !important;
        overflow: visible !important;
        text-overflow: ellipsis;
        position: relative;
        overflow-wrap: anywhere;
        color: inherit;
        //TODO: in later version create UI for edit mode
        // &--new {
        //     cursor: text;
        //     &:hover {
        //         border: 1px dotted lightgray;
        //         color: var(--color-active-light);
        //     }
        // }
        .actionFlag {
            visibility: hidden;
            opacity: 0;
            transition: all 0.2s ease;
            position: absolute;
            left: 0;
            top: 0;
        }
        &:hover .actionFlag {
            visibility: visible;
            opacity: 1;
        }
        &--subGroup {
            transition: all 0.2s ease-in-out;
            &.selected {
                background-color: var(--color-active);
                color: white;
            }
        }
        &--actions {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            display: grid;
            grid-auto-flow: column;
            gap: 0.5rem;
        }
        &:hover .tooltip {
            visibility: visible;
            opacity: 0.8;
            max-width: 90%;
            transition-delay: 1.3s;
            overflow-wrap: break-word;
            width: fit-content;
        }
        &::before {
            content: "ℹ";
            background-color: var(--color-active-trs);
            color: var(--color-active);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 1rem;
            width: 2rem;
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            visibility: hidden;
            opacity: 0;
        }
        &[data-error="true"] {
            &::before {
                background-color: var(--color-red-trs);
                color: #f3dcdc;
                // color: var(--color-red-trs);
                // background-color: #f3dcdc;
            }
        }
        &[data-info="true"] {
            &::before {
                visibility: visible;
                opacity: 1;
            }
        }
    }
    &__row:not(.table__headers) &__cell {
        border-left: 2px dotted var(--color-gray-3);
    }
    &__action {
        background-color: var(--color-white);
        display: grid;
        border-radius: 50%;
        padding: 1rem;
        transition: transform 0.2s ease-in-out;
        align-items: center;
        justify-content: center;
        width: 5rem;
        height: 5rem;
        &:hover {
            transform: scale(1.1);
        }
    }
    &__download {
        width: 5rem;
        height: 5rem;
        overflow: visible;
        position: absolute;
        bottom: -2rem;
        right: -2rem;
        background: #afb8fc21;
        border-radius: 50%;
        padding: 1rem;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        z-index: 1;
        @include activateButton();
    }
}

.tbl {
    display: grid;
    %cell {
        padding: 0.5rem;
    }
    &__actions {
        display: grid;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
        grid-auto-flow: column;
        .button {
            min-width: 10rem;
        }
    }
    &__header {
        @extend %cell;
        font-size: 2.2rem;
        font-weight: 500;
        border-bottom: 3px solid var(--color-gray-3);
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: var(--color-active);
        color: white;
        border: none;
        @media print {
            position: relative;
        }
    }
    &__check {
        @extend %cell;
        @extend .tbl__header;
        display: grid;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: white;
        overflow: hidden;
        .icon {
            fill: white;
        }
        &:hover {
            background-color: var(--color-active-light);
        }
    }
    &__cell {
        @extend %cell;
        font-size: 1.6rem;
        border-bottom: 1px dotted var(--color-gray-2);
        border-left: 2px dotted var(--color-gray-3);
        cursor: text;
        &--new {
            @extend .tbl__cell;
            & .input__field {
                color: var(--color-pending) !important;
            }
        }
        &.checked {
            background-color: var(--color-active-trs);
        }
        &--caption {
            @extend .tbl__cell;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.8rem;
            font-weight: 500;
            background-color: var(--color-gray-3);
        }
        &--select {
            @extend .tbl__cell;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;
            &:hover {
                .checkCell {
                    transition: all 0.2s ease-in-out;
                    transform: scale(1.1);
                }
            }
        }
        .input__field {
            border: none;
        }
    }
}
