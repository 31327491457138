.topBar {
	@media print {
		display: none !important;
		width: 0 !important;
		height: 0 !important;
	}
	box-shadow: var(--box-shadow-light);
	background-color: white;
	z-index: 1000;
	position: sticky;
	top: 0;
	left: 0;
	width: 100%;
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 10rem 1fr auto auto;
	grid-template-rows: min-content 3rem;
	align-items: center;
	gap: 0.2rem 2rem;

	.systemMode {
		position: absolute;
		bottom: 1rem;
		right: 1rem;
	}
	.logo {
		grid-column: 1;
		width: 10rem;
	}
	&__title {
		grid-column: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		.heading {
			color: var(--color-active);
			justify-self: center;
		}
		&-text {
			font-size: 3.2rem;
			font-weight: 500;
			color: var(--color-active);
		}
	}

	&__usersView {
		grid-column: 3;
		align-self: flex-start;
		display: grid;
		align-items: center;
		justify-content: center;
		.userView {
			font-size: 1.6rem;
			color: white;
			border-radius: 50%;
			width: 5rem;
			background-color: seagreen;
			border: 1px solid var(--color-bg-3);
			height: 5rem;
			display: grid;
			align-items: center;
			justify-content: center;
			box-shadow: var(--box-shadow-2);
			&:not(:first-child) {
				margin-top: -0.8rem;
			}
		}
	}
	.userBox {
		grid-column: 4;
	}
	.chaining {
		grid-row: 2;
		margin-right: 33rem;
		svg.icon {
			fill: var(--color-active-light);
		}
	}
}
