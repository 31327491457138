.buildingsCard {
    //default card
    position: relative;
}
.buildings {
    display: grid;
    gap: 1rem;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: flex-end;
    grid-auto-flow: column;
    background-image: repeating-linear-gradient(#ccccccbf 0 0.1px, transparent 1px 100%);
}
.building {
    display: grid;
    grid-auto-flow: row;
    align-items: flex-end;
    align-content: end;
    justify-items: center;
    justify-content: center;
    align-self: end;
    padding: 1rem;
    gap: 1.5rem;
    grid-template-rows: 1fr min-content;
    &--new {
        background-color: var(--color-gray-trs);
    }
    &__title {
        grid-row: 2;
        padding: 1rem;
        color: var(--color-active);
        font-weight: 600;
        border-radius: 1rem;
        background-color: var(--color-bg-layout);
        cursor: pointer;
        width: 100%;
        text-align: center;
        transition: all 0.2s ease-in-out;
        @include activateButton();
    }
    &__floors {
        grid-row: 1;
        border: 20px solid gray;
        border-bottom: none;
        border-left: none;
        border-top: 15px solid var(--color-gray-2);
        border-radius: 2rem 0 2rem 2rem;
        display: grid;
        align-items: flex-end;
        grid-auto-flow: row;
        color: var(--color-gray-1);
        position: relative;
        min-width: 15rem;
        background-color: var(--color-bg-3);
        .add {
            @extend .tableCard__add;
            bottom: -1.5rem;
            left: -3.5rem;
            visibility: hidden;
            opacity: 0;
        }
    }
    &__floor {
        display: grid;
        align-items: center;
        justify-content: center;
        grid-auto-flow: column;
        gap: 0.5rem;
        cursor: pointer;
        text-align: center;
        transition: all 0.5s;
        padding: 0 0.5rem;
        grid-template-columns: [title] 1fr [from-to]repeat(3, min-content);
        &--new {
            color: var(--color-pending);
        }
        &:hover {
            background-color: var(--color-bg-2);
            & .remove {
                visibility: visible;
                opacity: 1;
            }
        }
        .remove {
            visibility: hidden;
            opacity: 0;
            @extend .table__action;
        }
        span {
            font-size: 1.8rem;
            color: var(--color-active);
            &.title {
                font-size: 2rem;
                font-weight: 600;
            }
            // &.from {
            // }
            // &.space {
            // }
            // &.to {
            // }
        }
    }
    &__floors:hover {
        .add {
            visibility: visible;
            opacity: 1;
        }
    }
}

.floorWizard {
    display: grid;
    gap: 1rem;
    grid-column: 1/-1;
    &__floor {
        display: grid;
        gap: 1rem;
        grid-auto-flow: column;
        align-items: center;
        grid-template-columns: min-content 1fr 1fr 1fr min-content;
    }
}
