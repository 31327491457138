@mixin highlightRow($side, $trigger) {
    & {
        position: relative;
    }
    &::before {
        visibility: hidden;
        opacity: 0;
        content: "";
        position: absolute;
        background: var(--color-active);
        transition: all 0.4s ease-in-out;
        @if ($side == "bottom") {
            width: 0;
            height: 0;
            bottom: 0;
        } @else {
            width: 0.5rem;
            height: 20%;
            top: 50%;
            transform: translateY(-50%);
            @if ($side == "right") {
                right: 0;
            } @else {
                left: 0;
            }
        }
    }
    @if ($trigger == "hover") {
        &:hover::before {
            visibility: visible;
            opacity: 0.4;
            height: 80%;
        }
        @if ($side == "bottom") {
            &:hover::before {
                height: 0.2rem;
                width: 100%;
            }
        }
    } @else if($trigger == "constant") {
        &::before {
            visibility: visible;
            opacity: 0.4;
            height: 80%;
        }
    } @else {
        &:focus-within::before {
            visibility: visible;
            opacity: 0.4;
            height: 80%;
        }
    }
}

@mixin activateButton() {
    &:hover {
        box-shadow: var(--box-shadow-1);
        transform: translateY(-0.3rem);
    }
    &:active {
        transform: translate(-0.1rem, 0.1rem);
        box-shadow: var(--box-shadow-light);
    }
}

// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) {
            @content;
        } //600px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) {
            @content;
        } //900px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) {
            @content;
        } //1200px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) {
            @content;
        } //1800px
    }
}

@mixin scrollbar() {
    &::-webkit-scrollbar {
        visibility: hidden;
        // width: 0;
        width: 1rem;
        height: 1rem;
        opacity: 0;
        background-color: var(--color-gray-trs);
        border-radius: 50%;
        transition: all 0.2s ease-in-out;
    }
    &::-webkit-scrollbar-thumb {
        opacity: 0;
        visibility: hidden;
        background-color: var(--color-gray-2);
        border-radius: 5rem;
    }
    &::-webkit-scrollbar-thumb {
        opacity: 1;
        visibility: visible;
    }
    &:hover::-webkit-scrollbar {
        opacity: 1;
        visibility: visible;
    }
}
