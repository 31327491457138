.notification {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;
	cursor: pointer;
	.icon {
		fill: white;
		stroke: var(--color-active-light);
	}
	&__count {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 1.6rem;
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--color-red);
		color: #fff;
		z-index: 1;
		animation: shake 1.2s ease-in-out;
		&.static {
			animation: none;
		}
		&--gray {
			@extend .notification__count;
			background-color: var(--color-active-light);
		}
	}
	&__messages {
		position: absolute;
		padding: 2rem;
		box-shadow: var(--box-shadow-1);
		background-color: #fff;
		border-radius: 2rem;
		top: 100%;
		left: 0;
		visibility: hidden;
		opacity: 0;
		display: grid;
		gap: 0.5rem;
		z-index: 1;
		min-width: 30rem;
		transition: all 0.4s ease-out;
		&[data-show] {
			visibility: visible;
			opacity: 1;
		}
	}
	&__msg {
		display: grid;
		grid-auto-flow: column;
		grid-template-columns: 1fr 3rem 3rem;
		color: var(--color-non-active);
		gap: 1rem;
		align-items: center;
		justify-items: start;
		padding-right: 1rem;
		@include highlightRow($start, hover);
		.button--icon {
			width: 5rem;
			height: 5rem;
		}
	}
	&__body {
		font-size: 1.6rem;
	}
}
