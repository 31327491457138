.tableCard{
    .filters {
        display: grid;
        grid-template-columns: 1fr min-content;
        margin-bottom: 2rem;
        align-items: center;
        gap: 1rem;
        .icon{
            cursor: pointer;
        }
        &__search{
            justify-self: end;
            display: flex;
            .icon{
                align-self: center;
                &--active{
                    fill: var(--color-active);
                }
            }
            &--active{
                .icon{
                    fill: var(--color-active);
                }
            }
        }
        &__rows {
            grid-column: 1/-1;
            display: grid;
            gap: .5rem;
        }
        &__row {
            background-color: var(--color-gray-trs);
            padding: 1rem;
            border-radius: 1rem;
            display: grid;
            gap: 1rem;
        }
        &__caption {
            cursor: pointer;
            display: grid;
            grid-auto-flow: column;
            gap: 1rem;
            align-items: center;
            justify-content: flex-start;
            &--open {
                border-bottom: 1.5px solid var(--color-bg-2);
            }
        }
        &__buttons {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
            gap: 1rem;
            padding: 0.5rem;
        }
        &__button {
            padding: 0.5rem;
            border-radius: 1rem;
            font-size: 1.4rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: var(--color-bg-2);
            &--active {
                background-color: var(--color-active);
                color: white;
            }
        }
        &__toggleButtons{
            display: grid;
            grid-auto-flow: column;
            justify-self: flex-end;
            gap: 1rem;
            font-size: 1.4rem;
            color: var(--color-active);
            font-weight: 500;
            align-items: center;
        }
    }
}