.errorFlag {
    position: relative;
    white-space: pre-wrap;
    overflow: visible;
    z-index: 100;
    &__icon-error {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        fill: var(--color-red-trs);
        z-index: 200;
        cursor: pointer;
    }
    &__msg {
        background-color: white;
        color: var(--color-red-trs);
        padding: 1rem;
        z-index: 300;
        font-weight: var(--font-w-3);
        font-size: 1.6rem;
        border-radius: 1rem;
        box-shadow: var(--box-shadow-light);
        display: grid;
    }
    &__close{
        cursor: pointer;
    }
}
