.tooltip {
    cursor: context-menu;
    white-space: pre-line;
    z-index: 9999;
    user-select: text;
    position: absolute;
    visibility: hidden;
    top: 0;
    transform: translateY(-100%);
    opacity: 0;
    width: 0;
    border-radius: 1rem;
    background-color: var(--color-active-dark);
    color: white;
    padding: 1rem;
    &.bottom {
        transform: translateY(100%);
    }
    &__text {
        font-size: 1.6rem;
        text-align: center;
        transition: opacity 0.2s 1.5s;
        color: inherit;
    }
    &__close {
        cursor: pointer;
        border-radius: 50%;
        padding: 0.5rem;
        position: absolute;
        top: 0;
        right: 0;
        transition: all 0.2s ease-in-out;
        &:hover {
            transform: scale(2) rotate(-180deg);
            box-shadow: var(--box-shadow-light);
        }
    }
    &::after {
        position: absolute;
        top: 100%;
        right: 15%;
        content: "";
        width: 0;
        height: 0;
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-bottom: none;
        border-top: 1rem solid var(--color-active-dark);
    }
    &.bottom::after {
        top: 0;
        transform: translateY(-100%);
        border-top: none;
        border-bottom: 1rem solid var(--color-active-dark);
    }
    &.show {
        display: block;
    }
    &.hide {
        display: none;
    }
}
