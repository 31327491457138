.actuals {
    display: grid;
    grid-template-columns: 1fr 1fr;
    &__cell {
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        padding: 1rem;
        border: 0.1px solid rgba(0, 0, 0, 0.2);
        &--header {
            background-color: navy;
            color: #fff;
        }
        &--value {
            font-size: 1.6rem;
        }
        &.current {
            font-weight: 600;
            color: var(--color-active);
        }
    }
}
