.calculatorCard {
    justify-content: center;
}
.calculator {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    justify-items: center;
    width: fit-content;
    gap: 1rem;
    background: var(--color-bg-3);
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: var(--box-shadow-light);
    .number {
        border-radius: 1rem;
        height: 3rem;
        padding: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-bg-3);
        width: 100%;
        box-shadow: inset -3px -2px 9px 0px var(--color-active-trs);
        transition: all .2s ease-in-out;
        &--span-2 {
            @extend .number;
            grid-column: span 2;
        }
        &:active {
            box-shadow: none;
            opacity: .9;
        }
    }
    .op {
        @extend .number;
        background-color: var(--color-active-light);
        color: white;
        font-size: 3.2rem;
        &--red {
            @extend .op;
            background-color: var(--color-pending);
        }
    }
    .result {
        grid-column: 1 / -1;
        border: 1px solid var(--color-active-light);
        padding: 1rem;
        border-radius: 2px;
        width: 100%;
        direction: ltr;
        color: var(--color-active-light);
        font-size: 3.2rem;
        font-weight: 500;
        user-select: all;
    }
    .eq {
        @extend .number;
        grid-column: 1;
        grid-row: 2 / 6;
        background: var(--color-active-dark);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2.8rem;
        height: 100%;
    }
}
