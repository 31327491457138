.contract {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    scroll-behavior: smooth;
    & > .button {
        grid-column: 1/-1;
        justify-self: center;
        min-width: 25rem;
    }
    .formCard__caption {
        .icon {
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
                transform: scale(1.5);
            }
            &:active {
                transform: scale(0.9);
            }
        }
    }
    .workspace {
        grid-column: 1 / -1;
    }
    .sections {
        display: grid;
        gap: 1rem;
    }
    .toggleCheck {
        justify-self: flex-end;
        display: grid;
        align-items: center;
        grid-auto-flow: column;
        gap: 1rem;
        padding: 1rem;
        border-radius: 2rem;
        font-size: 1.6rem;
        color: var(--color-active-light);
        &:hover {
            background-color: #6495ed12;
        }
        &--checked {
            @extend .toggleCheck;
            border: 1px solid var(--color-active);
            font-weight: 500;
        }
    }
    .tableFiles {
        grid-column: 1 / -1;
    }
    &__nav {
        grid-column: 1 / -1;
        justify-self: flex-start;
        min-width: 50rem;
        & .nav__link {
            font-size: 2.4rem;
            font-weight: 500;
        }
    }
    &__details {
        grid-column: 1/-1;
        position: relative;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
        .iconEdit {
            position: absolute;
            cursor: pointer;
            top: 2rem;
            left: 2rem;
            padding: 0.5rem;
            display: grid;
            align-items: center;
            justify-items: center;
            justify-content: center;
            gap: 0.1rem;
            color: var(--color-active);
            border-radius: 50%;
            transition: all 0.2s ease-in;
            span {
                opacity: 0;
                visibility: hidden;
            }
            &:hover {
                transform: scale(1.1);
                background-color: var(--color-bg-3);
                & .icon {
                    fill: var(--color-active);
                }
                & span {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
    &__totals {
        .filterColumns {
            grid-column: 1/-1;
            display: grid;
            grid-auto-flow: column;
            justify-self: flex-end;
            gap: 1rem;
            align-items: center;
            padding-bottom: 1rem;
            &__button {
                padding: 0.5rem;
                border-radius: 1rem;
                font-size: 1.4rem;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                background-color: var(--color-bg-2);
                &.active {
                    background-color: var(--color-active);
                    color: white;
                }
            }
        }
        &.paymentsCard {
            grid-template-columns: max-content 1fr;
        }
        grid-column: 1/-1;
        display: grid;
        gap: 0;
        overflow-x: scroll;
        .row,
        .caption,
        .nav {
            grid-column: 1/-1;
        }
        .nav {
            margin-bottom: 3rem;
        }
        .button {
            margin-top: 4rem;
            justify-self: center;
            grid-column: 1/-1;
        }
        .bold {
            font-weight: 600;
        }

        .billings {
            display: grid;
        }
        .payments {
            display: grid;
        }
        .accounting {
            display: grid;
            grid-template-columns: 1fr 1fr;
            &__caption {
                grid-column: 1/-1;
                margin-top: 2rem;
                text-align: center;
                padding: 1rem;
                border-bottom: 1px solid var(--color-active-trs);
                margin-bottom: 1rem;
                display: grid;
                align-items: center;
                justify-content: center;
                grid-template-columns: 1fr max-content;
                .button {
                    grid-column: 2;
                    margin: 0;
                }
            }
            &__calc {
                grid-column: 1;
                display: grid;
                align-items: center;
                justify-content: flex-start;
                gap: 0.5rem;
            }
            &__add {
                justify-self: flex-end;
                margin-top: 0;
                margin-bottom: 1rem;
            }
            &__billings {
                padding: 1rem;
                grid-column: 2;
                background-color: var(--color-bg-3);
                border: 1px solid lightgray;
                display: grid;
                align-content: start;
            }
            &__row {
                display: grid;
                grid-auto-flow: column;
                justify-content: flex-start;
                align-items: center;
                gap: 1rem;
                grid-template-columns: 15rem 15rem 20rem 1fr;
            }
            &__summery {
                margin-top: 2rem;
                padding-top: 1rem;
                border-top: 1px solid var(--color-active-light);
                grid-column: 1/-1;
                // grid-template-columns: 1fr 1fr 1fr 1fr;
            }
            .span {
                &--2 {
                    grid-column: span 2;
                }
                &--3 {
                    grid-column: span 3;
                }
            }
            .subtotal {
                width: 100%;
                border: 1px solid var(--color-gray-2);
                border-radius: 0.2rem;
                position: relative;
                background-color: var(--color-bg-3);
                .center {
                    text-align: center;
                }
                &__diff {
                    position: absolute;
                    left: 5px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 1.2rem;
                    font-weight: 700;
                }
                &--negative {
                    color: var(--color-red);
                }
                &--positive {
                    color: var(--color-active-light);
                }
            }
            .input {
                position: relative;
                &__sign {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 1.8rem;
                    color: var(--color-gray-1);
                }
                .openCalculator {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 1;
                    visibility: hidden;
                    opacity: 0;
                    transition: all 0.2s ease;
                }
                &:hover {
                    & .openCalculator {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }
        }
    }
    &__accounts {
        grid-column: 1;
        @include respond(phone) {
            grid-column: 1/-1;
        }
        .period {
            padding: 1rem;
            display: grid;
            grid-auto-flow: column;
            justify-items: flex-start;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            &__month {
                border: 1px solid lightgray;
                border-radius: 5px;
                padding: 0.5rem;
                cursor: pointer;
            }
        }
        .confirm {
            display: grid;
            font-size: 1.6rem;
            gap: 0rem !important;
            padding: 0;
            border: 1px solid white;
            background-color: #ebebeb;
            &__cell {
                padding: 0.5rem;
                margin: 0;
                border: 1px solid white;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            &__label {
                font-size: 1.2rem;
                color: gray;
            }
            &__header {
                background-color: cornflowerblue;
                color: white;
                font-weight: 500;
            }
            &__value {
                border: 1px dotted white;
                font-size: 1.6rem;
                text-align: center;
            }
        }
    }
    &__comments {
        grid-column: 2;
        display: grid;
        gap: 1rem;
        max-height: 40vh;
        overflow-y: scroll;
        align-content: stretch;
        @include respond(phone) {
            grid-column: 1/-1;
        }
        .caption {
            display: grid;
        }
        .toggleComments {
            justify-self: end;
            align-self: flex-start;
            grid-column: 2;
            font-size: 1.4rem;
            color: cornflowerblue;
            padding: 0.4rem;
            border-radius: 0.5rem;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
        .comments {
            display: grid;
            gap: 1rem;
            .comment {
                display: grid;
                gap: 0.5rem;
                grid-template-areas:
                    "t t t action"
                    "c c c c"
                    "c c c c"
                    "stage stage i i";
                background-color: #e2e6ad52;
                padding: 0;
                border-radius: 1.5rem;
                align-items: center;
                &__title {
                    padding: 0.5rem;
                    display: grid;
                    grid-auto-flow: column;
                    gap: 1rem;
                    align-items: center;
                    color: var(--color-active);
                    font-weight: 500;
                    white-space: break-spaces;
                    grid-area: t;
                    justify-self: start;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                &__content {
                    visibility: hidden;
                    opacity: 0;
                    height: 0;
                    transition: all 0.2s ease-in;
                    grid-area: c;
                    white-space: break-spaces;
                }
                &__info {
                    grid-area: i;
                    justify-self: flex-end;
                    padding: 1rem;
                    font-size: 1.2rem;
                    color: lightslategray;
                }
                &__stage {
                    grid-area: stage;
                    padding: 1rem;
                    font-size: 1.2rem;
                    color: lightslategray;
                }
                &__pin {
                    cursor: pointer;
                    transition: all 0.2s ease-in;
                    &:hover {
                        transform: scale(1.2);
                    }
                    &:active {
                        transform: scale(0.9);
                    }
                    &--pinned {
                        @extend .comment__pin;
                        fill: var(--color-active);
                    }
                    &--unpinned {
                        @extend .comment__pin;
                        fill: none;
                        stroke: var(--color-non-active);
                    }
                }
                &--add {
                    @extend .comment;
                    grid-template-areas: none;
                    align-items: center;
                    justify-content: center;
                    color: var(--color-active-light);
                    cursor: pointer;
                    transition: all 0.2s ease-in-out;
                    @include activateButton();
                }
                &:hover {
                    .comment__content {
                        visibility: visible;
                        opacity: 1;
                        height: auto;
                    }
                }
            }
        }
    }
    &__total {
        grid-column: 1/-1;
        .button {
            grid-column: 1 / -1;
            justify-self: center;
            align-self: center;
        }
    }
    &__sections {
        grid-column: 1/-1;
    }
    & > .nav {
        .nav__link {
            font-size: 2.2rem;
            font-weight: 600;
        }
    }
    &__additions {
        grid-column: 1;
    }
    &__subtractions {
        grid-column: 2;
    }
    .heading {
        grid-column: 1/-1;
        text-align: center;
        color: var(--color-gray-1);
    }
    hr {
        grid-column: 1/-1;
        margin-top: 2rem;
        height: 2rem;
        background: var(--color-bg-1);
        border: none;
        border-radius: 2rem;
    }
}

.adminContract {
    grid-template-columns: repeat(2, 1fr);
    &__details {
        grid-column: 1/-1;
    }
    &__accounts {
        grid-column: 1/-1;
    }
    &__actuals {
        grid-column: 1;
    }
    &__confirms {
        grid-column: 2;
    }
    .table__cell {
        overflow: hidden !important;
    }
}

.summery {
    display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 1rem;
    &__caption {
        color: var(--color-active-light);
        background-color: var(--color-gray-trs);
        padding: 1rem;
        border-radius: 1rem;
        font-weight: 500;
        text-align: center;
        grid-column: 1 / -1;
    }
    &__box {
        background-color: #455fa0e8;
        color: white;
        padding: 1rem;
        display: grid;
        justify-content: center;
        align-items: center;
        &.clickable {
            transition: all 0.2s ease-in-out;
            @include activateButton();
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
        &--badge-right{
            @extend .summery__box;
            background-color: var(--color-gray-trs);
            color: var(--color-active);
            font-weight: 600;
            border: none;
            border-left: 7px solid var(--color-active-trs);
        }
        &--black {
            @extend .summery__box;
            background-color: var(--color-gray-trs);
            border: 1px solid lightgray;
            color: var(--color-active);
        }
        &--red {
            @extend .summery__box;
            background-color: var(--color-red);
        }
        &--red-light {
            @extend .summery__box;
            background-color: var(--color-red-trs);
        }
        &--green {
            @extend .summery__box;
            background-color: #668832e6;
        }
    }
    &__label {
        text-align: center;
        font-size: 1.8rem;
    }
    &__total {
        text-align: center;
        font-size: 3rem;
        &--error {
            @extend .summery__total;
            color: red;
            -webkit-text-stroke: 0.3px #ffc4ae;
        }
        &--multipart {
            @extend .summery__total;
            display: grid;
            grid-auto-flow: column;
            align-items: center;
            justify-content: center;
            align-content: center;
            gap: 2rem;
        }
    }
}
