.workspace {
    margin-top: 2rem;
    display: grid;
    gap: 1rem;
    &__form {
        width: 100%;
        padding: 2rem 0 0 2rem;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
        gap: 2rem;
    }
    &__switchAdd {
        width: 100%;
        padding: 2rem 0 0 2rem;
        display: grid;
        gap: 2rem;
        justify-content: center;
    }
}

.ws {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1.5rem;
    &--collaspe {
        box-shadow: var(--box-shadow-1);
    }
    &__title {
        flex: 1;
        color: var(--color-non-active);
    }
    .action {
        margin-right: 1rem;
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 0.5rem;
        transition: all 0.4s ease-in-out;
        .icon {
            width: 3rem;
            height: 3rem;
        }
        &:hover {
            background-color: var(--color-bg-light);
            transform: scale(1.1);
            & .icon {
                fill: var(--color-active-light);
            }
        }
    }
}