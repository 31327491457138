.heading{
    font: inherit;
    color:var(--color-active);
    &--1{
        line-height: 0.94;
        font-size: 3.4rem;
        font-weight: var(--font-w-1);
    }
    &--2{
        line-height: 0.94;
        font-size: 3.2rem;
        font-weight: var(--font-w-2);
    }
    &--3{
        font: inherit;
        font-size: 2.4rem;
        font-family: var(--font-family-2);
        font-weight: var(--font-w-3) !important;
    }
}