$dir: rtl;
$start: right;
$end: left;

:root {
    //LANGUGE
    --dir: #{$dir};
    --lang: "he";

    // COLORS
    --color-bg-layout: #ecf0f6;
    --color-bg-1: #d3d3d3a1;
    --color-bg-2: #d3d3d380;
    --color-bg-3: #ecf0f661;

    --color-non-active: #a2a2c4;
    --color-active: #234aa9;
    --color-active-light: #2349a99f;
    --color-active-trs: #2349a94d;
    --color-active-dark: #333666;

    --color-red: #eb2f64;
    --color-red-trs: #ea2e6399;

    --color-pending: #c7380d;

    --color-gray: #222;
    --color-gray-1: #494141;
    --color-gray-2: #d3d3d3;
    --color-gray-3: #ebe8e8;

    --color-gray-trs: #ecf0f678;

    --color-white: #fff;
    --color-black: #000;

    // FONTS
    --def-font-size: 1.6rem;
    --def-font-family: Rubik, "Tahoma", sans-serif;
    --font-family-1: var(--def-font-family);
    --font-family-2: Alef, "Tahoma", sans-serif;
    --font-w-1: 300;
    --font-w-2: 400;
    --font-w-3: 700;

    // BLOCKS
    --box-shadow-light: 0 1rem 2rem rgba(0, 0, 0, 0.1);
    --box-shadow-1: 0 1rem 2rem rgba(0, 0, 0, 0.2);
    --box-shadow-2: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);
    --box-shadow-3: 0.1rem 0.9rem 2rem rgba(0, 0, 0, 0.4);
}

/* GLOBAL RESET */
* {
    padding: 0;
    margin: 0;
    &,
    &::before,
    &::after {
        font-size: 62.5%;
        box-sizing: border-box;

        @include respond(tab-land) {
            // width < 1200?
            font-size: 60%; //1 rem = 9px, 9/16 = 50%
        }

        @include respond(tab-port) {
            // width < 900?
            font-size: 55%; //1 rem = 8px, 8/16 = 50%
        }
        @include respond(phone) {
            // width < 900?
            font-size: 50%; //1 rem = 8px, 8/16 = 50%
        }

        @include respond(big-desktop) {
            font-size: 65%; //1rem = 12, 12/16
        }
    }
}
body {
    direction: var(--dir);
    font-family: var(--def-font-family), sans-serif;
    font-weight: var(--font-w-1);
    line-height: 1.8;
    user-select: none;
    @include scrollbar();
}
.u-scroll {
    @include scrollbar();
}
a {
    text-decoration: none;
    color: currentColor;
}
div {
    font-size: 2rem;
}

h2 {
    font-size: 3rem;
    line-height: 0.94;
    font-weight: var(--font-w-2);
}
h3 {
    font-size: 2.5rem;
    line-height: 0.94;
    font-size: 3.2rem;
    font-weight: var(--font-w-2);
}

hr {
    @extend .no-print;
    grid-column: 1/-1;
    margin-top: 2rem;
    margin-bottom: 2rem;
    height: 2rem;
    background: var(--color-bg-1);
    border: none;
    border-radius: 2rem;
}

.center {
    text-align: center;
    justify-self: center;
    align-self: center;
}
.ltr {
    text-align: center;
    direction: ltr;
}

.tag {
    text-align: center;
    padding: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
}
.non-active {
    @extend .tag;
    color: var(--color-non-active) !important;
}
.active {
    @extend .tag;
    background-color: var(--color-active) !important;
    color: white !important;
}
.danger {
    @extend .tag;
    background-color: #ea2e6333 !important;
    color: var(--color-red) !important;
}
.info {
    @extend .tag;
    background-color: var(--color-active-trs) !important;
    color: var(--color-active) !important;
}
.pending {
    @extend .tag;
    background-color: var(--color-pending) !important;
    color: var(--color-white) !important;
}

.link {
    text-decoration: none;
    font: inherit;
    color: var(--color-active);
    cursor: pointer !important;
    transition: all 0.2s ease-in-out;
    overflow-wrap: anywhere;
    &:hover {
        text-decoration: underline;
        transform: scale(1.01);
    }
}

.bigLink {
    padding: 3rem;
    height: fit-content;
    width: fit-content;
    color: white;
    background-color: navy;
    border-radius: 1rem;
    font-size: 2rem;
    cursor: pointer;
    user-select: none;
}
.buttonSwitch {
    display: flex;
    align-items: center;
    border: 1px solid var(--color-active-trs);
    padding: 0;
    margin: 0;
    justify-content: center;
    border-radius: 0.5rem;
    cursor: pointer;
    div {
        width: 100%;
        text-align: center;
        color: var(--color-non-active);
        padding: 0.5rem;
        transition: all 0.2s ease-in;
        &:hover {
            transform: scale(1.1);
        }
        &.selected {
            color: var(--color-active);
            font-weight: 500;
            width: 100%;
        }
    }
}

.settingsRow {
    background-color: var(--color-gray-1);
    padding: 1rem;
    display: grid;
    justify-content: start;
    gap: 2rem;
    grid-auto-flow: column;
}

//TODO: set as component
.caption {
    font-size: 2.4rem;
    font-weight: var(--font-w-2);
    color: cornflowerblue;
    &--collaspe {
        box-shadow: var(--box-shadow-light);
    }
}

//TODO: set as component
.flag {
    background-color: #888;
    color: #fff;
    padding: 0.4rem;
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
    &--new {
        @extend .flag;
        background-color: var(--color-pending);
    }
    &--txt {
        @extend .flag;
        background: none;
        color: var(--color-active);
    }
}

//TODO: set as component
.formCard {
    position: relative;
    &__caption {
        grid-column: 1/-1;
        margin-bottom: 3rem;
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
    }
    &__flag {
        position: absolute;
        left: 1rem;
        top: 1rem;
    }
    &__actions {
        grid-column: 1/-1;
        justify-self: center;
        margin-top: 3rem;
        display: grid;
        gap: 2rem;
        grid-auto-flow: column;
    }
    &__save {
    }
    &__abort {
        @extend .button--gray;
    }
}

*[data-tooltip] {
    transition: all 0.3s ease-out;
    overflow: visible !important;
    position: relative;
    &:hover .tooltip {
        visibility: visible;
        opacity: 0.8;
        width: fit-content;
        min-width: 10rem;
        // max-width: 90%;
        transition-delay: 1.3s;
    }
}

%icon-close-box {
    position: absolute;
    right: 1rem;
    top: 1rem;
    fill: white;
    z-index: 1000;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        background-color: var(--color-red-trs);
        transform: scale(1.5);
    }
}

.print-only {
    display: none !important;
    @media print {
        display: block !important;
    }
}
.no-print {
    @media print {
        display: none !important;
        width: 0;
        height: 0;
    }
}
.printTitle {
    @extend .print-only;
    @media print {
        grid-column: 1/-1;
        width: 100%;
        display: grid !important;
        gap: 1rem;
        align-items: center;
        grid-template-columns: 1fr max-content max-content;
        justify-items: center;
        grid-auto-flow: column;
    }
}

.reportTable {
    display: grid;
    grid-column: 1/-1;
    gap: 0;
    &__title {
        background-color: navy;
        color: white;
        grid-column: 1/-1;
        font-size: 2.4rem;
        text-align: center;
    }
    .filterColumns {
        grid-column: 1/-1;
        display: grid;
        grid-auto-flow: column;
        justify-self: flex-end;
        gap: 1rem;
        align-items: center;
        padding-bottom: 1rem;
        &__button {
            padding: 0.5rem;
            border-radius: 1rem;
            font-size: 1.4rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: var(--color-bg-2);
            &.active {
                background-color: var(--color-active);
                color: white;
            }
        }
    }
    .row,
    .caption,
    .nav {
        grid-column: 1/-1;
    }
    .nav {
        margin-bottom: 3rem;
    }
    .row {
        font-weight: 500;
        &__total {
            display: flex;
            align-items: center;
            justify-content: center;
            &.clickable {
                cursor: pointer;
                font-weight: 500;
                &:hover {
                    text-decoration: underline;
                }
            }
            &--sub {
                @extend .row__total;
                border-top: 2px solid #403151;
                color: #403151;
                background-color: #ccc0da;
                user-select: text;
            }
            &--main {
                @extend .row__total;
                background: #403151;
                color: white;
                user-select: text;
                // background: #2349a90d;
            }
        }
        &__info {
            margin-top: 2rem;
            color: var(--color-non-active);
            padding: 2rem;
            text-align: center;
        }
        &__caption {
            text-align: center;
        }
    }
    .cell {
        padding: 0.5rem;
        font-size: 1.6rem;
        text-align: center;
        & .cell__actions {
            visibility: hidden;
            opacity: 0;
            transition: all 0.2s ease-in-out;
        }
        &:hover .cell__actions {
            visibility: visible;
            opacity: 1;
        }
        &--title {
            @extend .cell;
            background-color: #c4d79b;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
                text-decoration: underline;
            }
        }
        &--subtitle {
            @extend .cell;
            border-right: 8px solid #c4d79b;
            background-color: #6495edab;
            color: #fff;
            font-size: 1.6rem;
            font-weight: 600;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
                text-decoration: underline;
            }
        }
        &--header {
            @extend .cell;
            background-color: navy;
            color: #fff;
            // .contract__totals .cell--header.clickbale
            &.clickable {
                grid-auto-flow: column;
                align-items: center;
                cursor: pointer;
                transition: all 0.2s ease-in-out;
                &:hover {
                    text-decoration: underline;
                    & .icon {
                        transform: scale(1.1);
                    }
                }
            }
        }
        &--value {
            @extend .cell;
            border: 1px solid lightgray;
            user-select: all;
            font-weight: 500;
            &.clickable {
                cursor: pointer;
                font-weight: 600;
                &:hover {
                    text-decoration: underline;
                }
            }
            &.first {
                border-right: 8px solid #c4d79b;
            }
            &.current {
                color: var(--color-pending);
            }
            &::selection {
                background-color: var(--color-active-light);
                color: #fff;
            }
            &.pendingChanges {
                position: relative;
                cursor: pointer;
                user-select: none;
                transition: all 0.2s;
                background-color: white;
                &:hover {
                    box-shadow: var(--box-shadow-1);
                    transform: scale(1.1);
                    border: none;
                    z-index: 1;
                }
                &::after {
                    position: absolute;
                    top: 0.2rem;
                    left: 0.2rem;
                    content: "";
                    width: 1rem;
                    height: 1rem;
                    background-color: var(--color-pending);
                    border-radius: 50%;
                }
            }
        }
    }
    .bold {
        font-weight: 600;
    }
}
